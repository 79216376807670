import React from 'react'
import './navbar.scss'
import Metrisava from '../../assets/metrisAva.svg'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

export default function Navbar() {
   const isMobile = useSelector(state => state.dimensions.isMobile)

   return (
      <div className={classNames("navbar", {'isMobile': isMobile})}>
         <Link style={{margin: 0, padding: 0}} to='/'><img style={{height: '26px'}} src={Metrisava} alt="METRISAVA"/></Link>
         <div style={{flexGrow: 1}}/>
         {/* <Link to="/story">Story</Link>
         <Link to="/tools">Tools</Link>
         <Link to="/contact">Contact</Link> */}
      </div>
   )
}
