import React from 'react'

export default function Divider() {
   return (
      <div
         style={{
            width: '100%',
            height: '1px',
            backgroundColor: '#192630'
         }}
      />
   )
}
