import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AppStoreBadge from '../../assets/apple-store-badge.svg'
import DemoAVAQR from '../../assets/demo-ava-qr.svg'
import GooglePlayBadge from '../../assets/google-play-badge.png'
import Divider from '../Divider/divider'
import './page-home.scss'


export default function PageHome() {

   const { t } = useTranslation();


   // useEffect(() => {
   //    i18n.changeLanguage('en');
   // }, [])

   // If / when this content is used, use proper translations.
   // const txt1 = (
   //    <>
   //    <p>AVA on tarkoitettu teollisuusympäristöön auttamaan asiakasta hakemaan ratkaisuja prosessia vaivaaviin ongelmiin tai seuraamaan ja optimoimaan sitä kustannus- tehokkaasti uusien mittausten avulla. Usein prosesseissa on kohteita jotka ovat mahdottomia mitata perinteisillä tavoilla tai laitteistot sen suorittamiseen ovat todella kalliita.</p>
   //    <p>Tällä hetkellä AVA järjestelmä tarjoaa työkaluja selluteollisuuteen soodakattiloille ja puukentälle mutta lähitulevaisuudessa mittausjärjestelmän ekosysteemi laajenee koko sellutehtaan kattavaksi järjestelmäksi, minkä avulla voidaan tehdä mittauksia tällä (jännittävällä) uudella tavalla kaikissa sen eri prosesseissa.</p>
   //    <p style={{marginBottom: 0}}>AVA soveltuu hyvin kohteisiin joihin koskettava tai muu perinteinen mittaustekniikka ei yllä tai se ei ole kustannustehokasta tai anna mittaustulosta prosessin juuri sen hetken tilanteesta.</p>
   //    </>
   // )

   // const txt2 = (
   //    <>
   //    <p>AVA on nopea, helppokäyttöinen mittausjärjestelmä jonka työkalut on rakennettu antamaan prosesseista vertailukelpoista mittaustietoa, jonka perusteella voidaan tehdä oikeita ja tuottavia ratkaisuja ja AVA mittausten antaman tiedon avulla prosessia voidaan optimoida jolloin joko sen tuottavuus, säästöt tai tehokkuus kasvavat ja sen ongelmiin tai esimerkiksi sen kulumiseen tai likaantumiseen voidaan päästä käsiksi.</p>
   //    <p>Usein perinteiset mittaustavat eivät pysty välttämättä antamaan tarvittavaa tietoa prosessista ja sen tilasta. Mittauksen suorittaminen niillä voi joissakin kohteissa olla hyvin kallista, jopa mahdotonta tai mittauksen toimivuus tarvitsee paljon huoltoa tai ei ole olemassa järjestelmiä joilla se voidaan tehdä.</p>
   //    <p style={{marginBottom: 0}}>AVA on kustannustehokas koska usein samasta kuvasta voidaan tehdä usein useita eri mittauksia ja AVA mittaukset eivät tarvitse kosketusta prosessiin jolloin sitä eivät vaivaa esimerkiksi kuluminen tai korroosio ja sen mittaustyökaluissa ei ole huollettavia osia ja näin ollen tarvittavien varaosien määrä on hyvin pieni.</p>
   //    </>
   // )
   // const txt3 = (
   //    <>
   //    <p>AVA mittauksia on saatavilla pilvi ja on-site (kiinteinä tehdas) asennuksina. AVA-työkalut sisältävät mittauksia, jotka voidaan tehdä helposti niihin suunnitellulla mobiilisovelluksella, tai mittaukset voivat perustua jonkin prosessin osa-alueen jatkuvatoimiseen analyysiin tai prosessista otettavaan automaattiseen näytteenottoon.</p>
   //    <p>Mittaustyökalut jotka eivät perustu jatkuvaan online analyysiin tai mittaamiseen voidaan toimittaa myös pilvipalveluina, joiden käyttöönotto ja ylläpito ovat hyvin nopeaa ja joustavaa. Mobiilisovelluksella suoritettavat mittaukset ovat helppokäyttöisiä ja niihin liittyvät mittausympäristöt yksinkertaisia.</p>
   //    <p style={{marginBottom: 0}}>Pilvipalvelussa mittaustulokset kerätään asiakaskohtaiseen tietokantaan, josta niitä voidaan tarkastella AVA.n omilla trendityökaluilla tai ne voidaan helposti siirtää analysoitaviksi toisiin järjestelmiin. Pilvipalvelut eivät ole suoraan yhteydessä asiakkaan tietojärjestelmiin, joten niiden avulla ei voida missään tilanteissa häiritä tuotantoa ja ne ovat hyvin tietoturvallisia. AVA työkalujen yksi yhteinen käyttöliittymä helpottaa käyttäjähallintaa ja työkalujen käytettävyyttä ja pitää ne korkealla tasolla.</p>
   //    </>
   // )

   return (
      <div className="page-home">
         <Header/>
         <Divider/>
         <div className="container">
            <h2>{t('home.product')}</h2>
            {/* <p>Product information can be found in:</p> */}
            <a href="https://www.andritz.com/metris-ava" target="_blank" rel="noreferrer"><p style={{color: '#0075BE'}}>www.andritz.com/metris-ava</p></a>
         </div>
         <Divider/>
         <div className="container">
            <h2>{t('home.get-started')}</h2>
            <p>{t('home.insrtuctions.a')}</p>

            <a className="store-link" href="https://apps.apple.com/fi/app/id1488306031" target="_blank" rel="noreferrer">
               <img alt="Download on app store" src={AppStoreBadge} style={{height: '40px', marginRight: '20px'}}/>
            </a>
            <a className="store-link" href="https://play.google.com/store/apps/details?id=com.andritz.metris.ava.reactnativehmi.android" target="_blank" rel="noreferrer">
               <img alt="Download on app store" src={GooglePlayBadge} style={{height: '40px'}}/>
            </a>
            <p>{t('home.insrtuctions.b')} <a href="https://cloud.ava.andritz.com" style={{color: '#0075BE'}}>https://cloud.ava.andritz.com</a>{t(' instance')}</p>
            <img
               style={{}}
               src={DemoAVAQR}
               alt="qr-code"
            />
            <p>{t('home.insrtuctions.c')}</p>
            {/* <Link style={{marginTop: '20px', color: '#0075BE', display: 'block', fontSize: 20, fontFamily: 'GilroySemi'}} to="/support">SUPPORT</Link> */}

         </div>

         <Divider/>
         <SupportSection/>
         {/* <SectionIntro/>
         <Divider/>
         <SectionWhy/>
         <Divider/>
         <SectionTextWithImage
            title="Mihin"
            text={txt1}
            image={Macbook}
         />
         <Divider/>
         <SectionTextWithImage
            title="Miksi"
            text={txt2}
            image={ProductsImage}
            mirror={true}
         />
         <Divider/>
         <SectionTextWithImage
            title="Miten"
            text={txt3}
            image={iPhone}
         />
         <SectionAndritz/>
         <SectionDemo/> */}
         <Divider/>
      </div>
   )
}

// // If used, localize
// const SectionIntro = () => {
//    return (
//       <div className="page-home__section-intro">
//          <div className="container">
//             <h2>AVA</h2>
//             <h6>
//                AVA tuo teollisuusympäristöön uuden, nopean ja helppokäyttöisen tavan mitata prosessia ja sen tapahtumia. Saatavan mittausdatan perusteella voidaan optimoida tuotantoa tai ratkaista prosesseissa olevia ongelmia. Näillä mullistavilla visuaalisilla mittauksilla voidaan saada mittaustuloksia ympäristöistä, joista se on ennen ollut mahdotonta, hidasta tai vaihtoehtoiset mittaustavat ovat hyvin kalliita, tarvitsevat paljon huoltoa tai niiden käyttöikä on lyhyt.
//             </h6>
//          </div>
//       </div>
//    )
// }


// // If used, localize
// const SectionAndritz = () => {
//    return (
//       <div className="page-home__section-andritz">
//          <div className="container">
//             <img src={AndritzLogoWhite} alt="Andritz"/>
//             <h6>
//                Kansainvälinen teknologiakonserni ANDRITZ on yksi maailman johtavista laitosten, laitteiden ja palvelujentoimittajista, sellu- ja paperiteollisuudelle. Vankan prosessituntemuksen avulla kehitetyt uudet AVA mittaustyökalut voivat antaa ajantasaista tietoa prosessin tilasta ja tukea sen optimointiin tai ne ovat yhdistettävissä ANDRITZ.n tarjoamaan prosessitukeen ja palveluun.
//             </h6>
//          </div>
//       </div>
//    )
// }

// // If used, localize
// const SectionDemo = () => {
//    return (
//       <div className="page-home__section-demo">
//          <div className="page-home__section-demo__background-image"/>
//          <div className="container">
//             <h2 style={{marginBottom: '18px'}}>Tilaa demo</h2>
//             <h6>
//                Mikäli haluat lisätietoa visuaalisista AVA-mittauksista tai haluat demo käyttäjäksi jollekin siihen soveltuvalle työkalulle ota yhteyttä ANDRITZ AVA myyntiin
//             </h6>
//             <a href="mailto:sales.ava@andritz.com?subject=AVA Demo Request">
//                <p>sales.ava@andritz.com</p>
//             </a>
//          </div>
//       </div>
//    )
// }

const SupportSection = () => {
   const { t } = useTranslation();
   return (
      <div className="container">
         <h2>{t('home.support.title')}</h2>

         <p>{t('home.support.txt')}</p>

         <div style={{display: 'flex', }}>
            <h5 style={{marginRight: '12px'}}>{t('home.support.contact')}</h5>
            <a href="mailto:support.ava@andritz.com?subject=Support">
               <h5 style={{color: '#0075BE'}} className="email">support.ava@andritz.com</h5>
            </a>
         </div>
      </div>
   )
}



const Header = () => {
   const dimensions = useSelector(state => state.dimensions)
   const isMobile = dimensions.isMobile
   const width = dimensions.width
   const {t} = useTranslation()

   const TxtContent = () => (
      <div style={isMobile ? {paddingTop: '60px'} : {}}>
         <h1>{t('header.title')}</h1>
         <h2 style={{margin: 0}}>{t('header.subtitle')}</h2>
      </div>
   )

   if (isMobile) {
      var triangleHeight = width / 3
      if (triangleHeight > 240) triangleHeight = 240
      const imageHeight = 360
      return (
         <div>
            <div className="container" style={{paddingBottom: 0}}>
               <TxtContent/>
            </div>
            <div>
            <div className="mobile-header-image" style={{height: `${imageHeight}px`, position: 'relative'}}>
               <svg height={triangleHeight} width={width} style={{position: 'absolute', top: '-1px', left: 0, right: 0}}>
                  <polygon points={`0,0 0,${triangleHeight} ${width},0`} style={{fill:'#000F1A', strokeWidth:0}} />
               </svg>
            </div>
            </div>
         </div>
      )
   }

   return (
      <header className="page-home__header">
         <div className="page-home__header__image">
            <div>
               <svg height="600" width="200">
                  <polygon points="0,0 200,0 200,600" style={{fill:'#000F1A', strokeWidth:0}} />
               </svg>
            </div>
         </div>
         <div className="page-home__header__content">
            <TxtContent/>
         </div>
      </header>
   )
}
