import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AndritzEngineered from '../../assets/andritz-logo-1.svg'
import AndrtizLogo from '../../assets/andritzLogo.svg'
import Envelope from '../../assets/envelope.svg'
import MetrisLogo from '../../assets/metris-logo.svg'
import MetrisAva from '../../assets/metrisAva.svg'
import './footer.scss'

export default function Footer() {
   const isMobile = useSelector(state => state.dimensions.isMobile)
   const {t} = useTranslation()

   return (
      <footer>
         <div className={classNames('footer', 'container', {'isMobile': isMobile})} style={{paddingBottom: '32px'}}>

            <div className="contact-info-row">
               <div>
                  <img src={Envelope} alt="" style={{height: '20px', marginTop: 0, marginBottom: '12px'}}/>
                  <h5>{t('footer.contact')}</h5>
                  <a href="mailto:sales.ava@andritz.com?subject=AVA Demo Request">
                     <h5 className="email">sales.ava@andritz.com</h5>
                  </a>
                  <img src={MetrisAva} alt="Andritz"/>
               </div>
            </div>


            <div className="navigation-row">
               {!isMobile && <img src={MetrisLogo} alt="Andritz"/>}
               <div className="navigation-items">
                  {/* <Link to="/">HOME</Link>
                  <div className="divider"/>
                  <Link to="/">STORY</Link>
                  <div className="divider"/>
                  <Link to="/">CONTACT</Link>
                  <div className="divider"/>
                  <Link to="/">TOOLS</Link>
                  <div className="divider"/> */}
                  {/* <Link to="/">PRIVACY POLICY</Link>  TODO: Enable provacy policy and terms of use links*/}
                  {/* <div className="divider"/> */}
                  {/* <Link to="/">TERMS OF USE</Link> */}
               </div>
            </div>

            {isMobile &&
               <img
                  src={AndritzEngineered}
                  alt="Metris"
                  style={{height: '54px', marginBottom: '32px'}}
               />
            }

            <div className="bottom-row" style={{width: '100%', justifyContent: 'space-between'}}>
               <p>©ANDRITZ {new Date().getFullYear()}</p>
               { !isMobile &&
               <div className="engineered-success">
                  <p className="blue-text">Digital IIoT Solutions</p>
                  <img src={AndrtizLogo} alt="Andritz"/>
               </div>
               }
            </div>
         </div>
      </footer>
   )
}
