import React, { Suspense, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import './App.scss'
import Footer from './components/Footer/footer'
import Navbar from './components/Navbar/navbar'
import PageHome from './components/PageHome/page-home'
import { useDispatch} from 'react-redux'
import { setWidth } from './store/actions'




function App() {
   const dispatch = useDispatch()

   useLayoutEffect(() => {
      const updateSize = () => {
         dispatch(setWidth(window.innerWidth))
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
   }, [dispatch]);


   return (
      <Suspense fallback="loading">
         <Router>
            <div className="app">
               <Navbar />

               <Routes>
                  {/* Tools and Support routes can be added if those pages are needed */}
                  <Route exact path="/" element={<PageHome/>} />
               </Routes>

               <Footer />
            </div>
         </Router>
      </Suspense>
   );
}

export default App;
